import Select,{ components } from 'react-select';
import '../../../style.css';
import { useEffect, useState } from 'react';

import agromap from '../../../assets/logo_agromap.png';
import sara from '../../../assets/Logo_SARA-2023.png';

import axios from 'axios';
import Swal from 'sweetalert2';

const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);
  
    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";
  
    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex "
    };
  
    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style
    };
  
    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

/* const allOptions = [
    { value: "Satisfaction,", label: "Satisfaction" },
    { value: "Enthousiasme,", label: "Enthousiasme" },
    { value: "Inspiration,", label: "Inspiration" },
    { value: "Motivation,", label: "Motivation" },
    { value: "Apprentissage,", label: "Apprentissage" },
    { value: "Mécontentement,", label: "Mécontentement" },
    { value: "Autres,,", label: "Autres" }
  ]; */

const baseUrl = "http://pointageapp.akidompro.com/api";
//const baseUrl = "http://127.0.0.1:8000/api";
function VisiteurPage(){

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [visiteurData,setVisiteurData] = useState({
        "nom":"",
        "tel":"",
        "email":"",
        "entreprise":"",
        "profession":"",
        "is_connaitre":"",
        "reseau_connaissance":"",
        
    });

    const [impressions,setImpressions] = useState([]);
    const [errorName,setErrorName] = useState('');
    const [errorEmail,setErrorEmail] = useState('');
    const [errorTel,setErrorTel] = useState('');

    useEffect(()=>{
        try {
            axios.get(baseUrl+'/impression-sara-list/').then((resp)=>{
                setImpressions(resp.data);
            })
        } catch (error) {
            
        }
    },[]);

    const handleChange=(event)=>{

        setVisiteurData({
            ...visiteurData,
            [event.target.name]:event.target.value
        });


    }

    useEffect(()=>{
        setErrorEmail('');
        setErrorName('');
        setErrorTel(''); 

        const pattern_email = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
        if(visiteurData.nom == ""){setErrorName('Le champs nom est vide')}
        if(visiteurData.email == ""){setErrorEmail('Le champs email est vide')}else if(!pattern_email.test(visiteurData.email)){setErrorEmail('Adresse e-mail invalide !')}
        if(visiteurData.tel == ""){setErrorTel('Le champs téléphone est vide')}   
    },[visiteurData])



    const allOptions = impressions.map((impression)=>(({
        label:`${impression.libelle}`,
        value:`${impression.id}`
    })));

    const handleChangeSelectOption=(selectOption)=>{
        setSelectedOptions(selectOption);
    }

    //console.log(selectedOptions)

    const submitForm =()=>{
        const _formData = new FormData();
        setErrorEmail('');
        setErrorName('');
        setErrorTel('');
        const pattern_email = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
        if(visiteurData.nom == ""){setErrorName('Le champs nom est vide')}
        if(visiteurData.email == ""){setErrorEmail('Le champs email est vide')}else if(!pattern_email.test(visiteurData.email)){setErrorEmail('Adresse e-mail invalide !')}
        if(visiteurData.tel == ""){setErrorTel('Le champs téléphone est vide')}
        const impression = selectedOptions.map((option)=>option.value);

        if(visiteurData.nom !="" && visiteurData.email !="" && visiteurData.tel !="")
        {
            _formData.append('nom',visiteurData.nom);
            _formData.append('email',visiteurData.email);
            _formData.append('tel',visiteurData.tel);
            _formData.append('entreprise',visiteurData.entreprise);
            _formData.append('profession',visiteurData.profession);
            _formData.append('is_connaitre',visiteurData.is_connaitre);
            _formData.append('reseau_connaissance',visiteurData.reseau_connaissance);
            _formData.append('impressions',impression);
            //console.log(_formData);

            try {
                axios.post(baseUrl+'/create-new-visiteur-sara/',_formData).then((resp)=>{
                    if(resp.data.bool)
                    {
                        let timerInterval
                            Swal.fire({
                            title: 'AGROMAP vous dit merci '+visiteurData.nom+' ',
                            html: 'Fermeture dans <b></b> millisecondes...',
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                                const b = Swal.getHtmlContainer().querySelector('b')
                                timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                                }, 100)
                            },
                            willClose: () => {
                                window.location.reload()
                                clearInterval(timerInterval)
                            }
                            }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log('I was closed by the timer')
                            }
                            })
                        
                    }
                })
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <div class="content">
    
        <div class="container" style={{"box-shadow": "0 0 10em white"}}>
          <div class="row align-items-stretch no-gutters contact-wrap">
            <div class="col-md-12">
              <div class="form h-100">
                <h3 class="text-center">{"\u2728"} FORMULAIRE DE PRESENCE</h3>
                <div class="mb-5" method="post" id="contactForm" name="contactForm">
                 
                  <div class="row">
                    <div class="col-md-12 form-group mb-3">
                      <label for="" class="col-form-label">Nom et prénoms <span className='text-danger'>*</span></label>
                      <input type="text" class="form-control" name="nom" onChange={handleChange} value={visiteurData.nom} placeholder="Nom et prénoms" />
                      {errorName !="" && <span className='text-danger'>{errorName}</span>}

                    </div>
                  </div>
    
                  <div class="row">
                    <div class="col-md-6 form-group mb-3">
                      <label for="" class="col-form-label">Téléphone <span className='text-danger'>*</span></label>
                      <input type="text" class="form-control" name="tel" onChange={handleChange} value={visiteurData.tel} placeholder="Numéro de Téléphone" />
                      {errorTel !="" && <span className='text-danger'>{errorTel}</span>}
                    </div>
    
                    <div class="col-md-6 form-group mb-3">
                      <label for="" class="col-form-label">Adresse e-mail <span className='text-danger'>*</span></label>
                      <input type="text" class="form-control" name="email" onChange={handleChange} value={visiteurData.email}  placeholder="Adresse e-mail" />
                      {errorEmail !="" && <span className='text-danger'>{errorEmail}</span>}
                    </div>
    
                  </div>
    
                  <div class="row">
                    <div class="col-md-6 form-group mb-3">
                      <label for="" class="col-form-label">Entreprise</label>
                      <input type="text" class="form-control" name="entreprise" onChange={handleChange} value={visiteurData.entreprise} placeholder="Entreprise" />
                    </div>
                    <div class="col-md-6 form-group mb-3">
                      <label for="" class="col-form-label">Profession</label>
                      <input type="text" class="form-control" name="profession" onChange={handleChange} value={visiteurData.profession} id="company"  placeholder="Profession" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 form-group mb-3">
                      <label for="" class="col-form-label">Connaissez-vous AGROMAP ?</label>
                        <select className="form-control" name="is_connaitre" onChange={handleChange} value={visiteurData.is_connaitre}>
                            <option value="">choisir oui/non...</option>
                            <option value="OUI">OUI</option>
                            <option value="NON">NON</option>
                        </select>
                    </div>

                   
                    <div class="col-md-6 form-group mb-3">
                    <label for="" class="col-form-label">Comment avez-vous connu AGROMAP ?</label>
                    <select className="form-control" name="reseau_connaissance" onChange={handleChange} value={visiteurData.reseau_connaissance}>
                        <option value="">choisir...</option>
                        <option value="Réseau Facebook">Réseau Facebook</option>
                        <option value="Réseau Linkdin">Réseau Linkdin</option>
                        <option value="Site internet">Site internet</option>
                        <option value="Bouche-à-oreille">Bouche-à-oreille</option>
                        <option value="Télévision">Télévision</option>
                        <option value="Radio">Radio</option>
                        <option value="Sara 2023">Sara</option>
                        <option value="Un(e) Ami(e)">Un(e) Ami(e)</option>
                        <option value="Autres">Autres</option>
                    </select>
                </div>
                    
                  
                  </div>


    
                  <div class="row">
                    <div class="col-md-12 form-group mb-2">
                      <label for="message" class="col-form-label">Pourriez-vous nous dire quel type d'impression décrit le mieux votre expérience ?</label>
                        <Select 
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={allOptions}
                        onChange={handleChangeSelectOption}
                        components={{
                            Option: InputOption
                          }}
                        />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group mb-3">
                      <button class="btn btn-primary rounded-0 py-2 px-4 form-control" onClick={submitForm}>Valider</button>
                    </div>
                  </div>

                  <div class="row border rounded-2">
                    <div class="col-md-12 form-group text-center">
                      <img className='mt-1 mx-3' alt="Agro-map" src={agromap} width="105" />
                      <img className='mt-1' alt="Agro-map" src={sara}  width="80"/>
                    </div>
                  </div>
                </div>
    
    
              </div>
            </div>
    
          </div>
        </div>
    
      </div>
    )
}

export default VisiteurPage;