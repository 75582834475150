import moment from "moment";
import Clock from "../Clock";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";
import PDFGenerator from "./Rapport";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyPdfDocument from "./Rapport";


const baseUrl = "http://pointageapp.akidompro.com/api";
//const baseUrl = "http://127.0.0.1:8000/api";
function Dashboard() {
    const navigate = useNavigate()
    const currentDate = new Date();
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_ucl'));
    const [client,setClients] = useState([]);

    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const [dateDay,setDateDay] = useState(formattedDate);
    const [employes,setEmployes] = useState([]);
    const [loading,setLoading] = useState(false);
    const [nameComponent,setNameComponent] = useState('journee');
    const [dayOpen,setDayOpen] = useState([]);
    const [listEmployeDays,setListEmployeDays] = useState([]);
    const [employeArriveTotal,setEmployeArriveTotal] = useState(0);
    const [employePartiTotal,setEmployePartiTotal] = useState(0);
    const [employeTotal,setEmployeTotal] = useState(0);
    const [employeAbsents,setEmployeAbsents] = useState(0);
    const [employeRetard,setEmployeRetard] = useState(0);

    const [listemployePresents,setEmployePresents] = useState([]);
    const [listEmployeAbsents,setLstEmployeAbsents] = useState([]);

    const [loadingList,setLoadingList] = useState(false);

    const [selectOptionEmploye,setSelectOptionEmploye] = useState(null);
    const [searchEmployeList,setSearchEmployeList] = useState([]);

    const [pointagesGroup,setPointageGroupe] = useState([]);

    const [mois,setMois] = useState('00');
    const [etat,setEtat] = useState('1');
    const [annees,setAnnees] = useState(`${year}`);

    const [absEnregistre,setAbsEnregistre] = useState(0);
    const [heurCumule,setHeurCumule] = useState(0);
    const [retardTotal,setRetardTotal] = useState(0);
    const [anneeLists,setAnneeLists] = useState([]);

    const [pdfData,setPdfData] = useState([]);

    const [typeAbsences,setTypeAbsences] = useState([]);
    const [absenceData,setAbsenceData] = useState({
        'absence':'',
        'motif':'',
/*         'dureeAbsence':'1',
        'tempsAbsence':'',
        'dateDepart':'', 
        'dateRetoutProvisoire':'', */
    });


    const moisList = [
        {'value':'00','lib':'Tous'},
        {'value':'01','lib':'Janvier'},
        {'value':'02','lib':'Février'},
        {'value':'03','lib':'Mars'},
        {'value':'04','lib':'Avril'},
        {'value':'05','lib':'Mai'},
        {'value':'06','lib':'Juin'},
        {'value':'07','lib':'Juillet'},
        {'value':'08','lib':'Aôut'},
        {'value':'09','lib':'Septembre'},
        {'value':'10','lib':'Octobre'},
        {'value':'11','lib':'Novembre'},
        {'value':'12','lib':'Décembre'},
    ]


    useEffect(()=>{

        if(isAuthToken == null){
            localStorage.removeItem('_token_ucl');
            navigate('/auth/');
        }
        if(isAuthToken !== null){
            try {
                axios.get(baseUrl+'/users-clients/?token='+isAuthToken,{
                    headers:{
                        'Content-Type':'application/json'
                    },
                    credentials:'include'
                }).then((resp)=>{
                    if(resp.data.failed == true){
                        setIsAuthToken(null);
                        setClients(null);
                        localStorage.removeItem('_token_ucl');
                        navigate('/auth/');
                    }
                    setClients(resp.data[0]);
                })
            } catch (error) {
                console.log(error);
            }
        }
        
        if(dateDay){
            try {
                axios.get(baseUrl+'/journee-ouverte-list/?active='+dateDay).then((resp)=>{
                    setDayOpen(resp.data[0]);
                })

                axios.get(baseUrl+'/pointage-day-list/?days='+dateDay).then((res)=>{
                    setListEmployeDays(res.data);
                })
            } catch (error) {
                console.log(error);
            }
        }

        try {
            axios.get(baseUrl+'/total-employes-stat/').then((resp)=>{
                if(resp.data.bool){
                    setEmployeTotal(resp.data.total)
                    setEmployeAbsents(resp.data.abs)
                }else{}
            })
        } catch (error) {
            console.log(error);
        } 

        try {
            axios.get(baseUrl+'/employes-list/?all').then((resp)=>{
                setEmployes(resp.data)
            })
        } catch (error) {
            console.log(error);
        } 

        try {
            axios.get(baseUrl+'/types-absences/').then((resp)=>{
                setTypeAbsences(resp.data)
            })
        } catch (error) {
            console.log(error);
        }

        const intervalId = setInterval(()=>{
            reloadListOpen();
       },2 * 60 * 1000);

       return () => {
        clearInterval(intervalId);
      };

    },[dateDay,isAuthToken]); 

    useEffect(()=>{
        if(dayOpen && dayOpen?.etat){ 
            totalEmployeStatToday();
        }

        if(searchEmployeList && searchEmployeList.length > 0){
            groupePOintage();
        }
        functAnneeList();
        if (etat == "2" && mois !="00" && annees !=""){
            createPDFRapport() 
        }
    },[dayOpen,searchEmployeList,mois,annees]);


    const reloadListOpen=()=>{
        if(dateDay){
            try {
                axios.get(baseUrl+'/pointage-day-list/?days='+dateDay).then((res)=>{
                    setListEmployeDays(res.data);
                })
            } catch (error) {
                console.log(error);
            }
        }
    }

 
    const totalEmployeStatToday=()=>{
        const _formData = new FormData();
        if(dayOpen?.etat){ 
            
            _formData.append('journee',dayOpen?.id);
            try {
                axios.post(baseUrl+'/total-employe-stat-to-day/',_formData).then((resp)=>{
                    if(resp.data.bool){
                        setEmployeArriveTotal(resp.data.arrive)
                        setEmployePartiTotal(resp.data.depart)
                        setEmployeRetard(resp.data.retard)
                    }else{}
                })
            } catch (error) {
                console.log(error);
            } 
        } 
    }

    const openListModalPresent=()=>{
        

        setLoadingList(true);
        try {
            axios.get(baseUrl+'/employes-list/?all').then((resp)=>{
                setEmployePresents(resp.data);
                setLoadingList(false);
            })
        } catch (error) {
            console.log(error);
        }

        window.$(`#openListPresent`).modal('show');
        
    }

    const openListModalAbsent=()=>{
        

        setLoadingList(true);
        try {
            axios.get(baseUrl+'/employes-list/?absent').then((resp)=>{
                setLstEmployeAbsents(resp.data);
                setLoadingList(false);
            })
        } catch (error) {
            console.log(error);
        }

        window.$(`#openListAbs`).modal('show');
        
    }

    const options = employes.map((employe)=>(({
        label:`${employe.nom} ${employe.prenom}`,
        value:`${employe.id}`
    })));

    const handleChangeSelectOptionEmploye=(selectOption)=>{
        setSelectOptionEmploye(selectOption.value);
    }

    //console.log([etat,mois,annees])

    //const GroupOrdersPointages = {}

    const searchWorkEmploye=(empl)=>{
        empl = selectOptionEmploye
        setHeurCumule(0)
        setSearchEmployeList([]);
         setLoading(true);
        try {
            axios.get(baseUrl+'/pointage-day-list/?etat='+etat+'&employe='+empl+'&mois='+mois+'&annees='+annees).then((resp)=>{
                setLoading(false);
                const groupeJournees = {}
                const emplAbs = []
                const retardList = []
                
                if(resp.data.length > 0){
                    let hours = 0;
                    resp.data.forEach(empl =>{
                        //cumuler le temps de travail
                         if (empl.dureeTravail !== null){
                            hours += empl.dureeTravail
                        } 
                        // compter le retard
                        if(empl.etatRetard){
                            retardList.push(empl)
                        }
                        const dateJournee = empl.journee.date;
                        if(!empl.status){
                            emplAbs.push(empl)
                        }
                        if(!groupeJournees[dateJournee]){ 
                            groupeJournees[dateJournee] = [];
                        }
                       
                        groupeJournees[dateJournee].push(empl);
                    });
        
                     setSearchEmployeList(groupeJournees);
                     setAbsEnregistre(emplAbs.length);
                     setHeurCumule(hours);
                     setRetardTotal(retardList.length);
                }
         
            })
        } catch (error) {
            console.log(error);
        } 

        //console.log(groupePOintage());
        

        setNameComponent("search");

        
    }

    const groupePOintage=()=>{ 
        const groupeJournees = {}

        if(searchEmployeList?.length > 0){
            searchEmployeList.forEach(empl =>{
                const dateJournee = empl.journee.date;
                if(!groupeJournees[dateJournee]){ 
                    groupeJournees[dateJournee] = [];
                }
               
                //console.log(dateJournee)
                groupeJournees[dateJournee].push(empl);
            });

             return groupeJournees;
             
        }

    }

    const logout = ()=>{
        setIsAuthToken(null);
        setClients(null);
        localStorage.removeItem('_token_ucl');
        navigate('/auth/');
    }

    const DesactiveFunctionEmpl =(empl)=>{
        // window.$("#modalChargement").modal('show');
        Swal.fire({
            title: 'ATTENTION !',
            text: 'Êtes-vous sûre de vouloir faire cette action.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText:"NON",
            confirmButtonText: 'OUI',
            cancelButtonColor:"red"
          }).then((result) => {
            if (result.isConfirmed) {
                try {
                    axios.get(baseUrl+'/employes-list/?empl='+empl).then((resp)=>{
                            setEmployePresents(resp.data);
                    })
                } catch (error) {
                    console.log(error);
                } 
            }
          }); 

        

    }

     const AddAbsenceTo=(nom,prenom,id)=>{
        //alert(index);
        window.$(`#openListPresent`).modal('hide');

        navigate(`/add-absence/?nom-complet=${nom} ${prenom}&empl=${id}`)
        
    } 

    function functAnneeList(){
        
        for (let i = year; i >= year - 10; i--) {
             anneeLists.push(`${i}`)
        }

    }

    

    //rapport pdf
    
    //console.log(anneeLists);

    // creation data pdf 
    function createPDFRapport(){
        if (etat == "2" && mois !="00" && annees !=""){
            const _formData = new FormData();
            _formData.append("mois",mois)
            _formData.append("annee",annees)

            try {
                axios.post(baseUrl+'/create-data-pdf/',_formData).then((res)=>{
                    setPdfData(res.data.pdfdatas);
                })
            } catch (error) {
                console.log(error);
            }

        }
    }

    const [errorAbsence,setErrorAbsence] = useState('');
    const [errorMotif,setErrorMotif] = useState('');
    /* const [errorDuree,setErrorDuree] = useState('');
    const [errorTemps,setErrorTemps] = useState('');
    const [errorDepart,setErrorDepart] = useState('');
    const [errorRetour,setErrorRetour] = useState(''); */

    const [loadingabs,setLoadingabs] = useState(false);
    const [pointID,setPointID] = useState(null);
    const [emplJustID,setEmplJustID] = useState(null);
    const [nameCplet,setNameCoplet] = useState('');
    const [modIdx,setModIdx] = useState(null);

    const openModalJustif=(index,pointageId,emplID,nom,prenom)=>{
        setPointID(pointageId)
        setEmplJustID(emplID)
        setNameCoplet(`${nom} ${prenom}`)
        setModIdx(index)
        window.$('#absModal'+index+'').modal('show');
    }

    const handleChange=(event)=>{
        setAbsenceData({
            ...absenceData,
            [event.target.name] : event.target.value
        })
    }

    const submitForm=()=>{
        const _formData = new FormData();
        setErrorAbsence('');
        setErrorMotif('');
        

        setLoading(true);
        if(absenceData.absence == ""){setErrorAbsence('Ce champs est obligatoire')}
        if(absenceData.motif == ""){setErrorMotif('Ce champs est obligatoire')}

        if (absenceData.absence !== "" && absenceData.motif !== "" ){
            _formData.append('absence',absenceData.absence);
            _formData.append('motif',absenceData.motif);

            _formData.append('employeId',emplJustID);
            _formData.append('pointageId',pointID);

            //console.log(_formData);
            try {
                axios.post(baseUrl+'/save-absence-justify/',_formData).then((resp)=>{
                    setLoading(false);
                   // window.location.reload();
                     if(resp.data.bool){
                        searchWorkEmploye(emplJustID);
                    }
                    window.$('#absModal'+modIdx+'').modal('hide');
                    //navigate(`/dashboard/`); 

                    setPointID(null)
                    setEmplJustID(null)
                    setNameCoplet('')
                    setModIdx(null)
                })
            } catch (error) {
                console.log(error);
            }
        }
        setLoading(false);
    }

    return (
        <>
              <div className=" mt-4 mx-3">
            <div className="row">
                <aside className="col-md-2"> 
                 <div className="card bg-warning">
                    <div className="list-group list-group-flush ">
                        <Link className="list-group-item list-group-item-action text-primary" to={`/dashboard/`}>Accueil</Link> 
                         <Link className="list-group-item list-group-item-action text-primary" to={`/list-absence/`}>Gestion des Absences</Link> 
                        <Link className="list-group-item list-group-item-action text-primary" to={`/statistique-generale/`}>Statistique Générales</Link>
                         <button className="list-group-item list-group-item-action text-primary " onClick={()=>logout()}>Se Deconnecter</button> 
                    </div>
                </div> 
                </aside>
                <section className="col-md-9">
                    <div className="card">
                        <h5 className="card-header text-center">
                            <b className="mx-3">{moment(formattedDate).format("dddd,Do MMMM ")}</b>
                            <span className="text-center"><Clock /></span>
                        </h5>
                        <div className="card-body">

                            <section className="col-md-12 table-responsive-sm">
                                <div className="row">
                                        <div className="col-md-6">
                                            <div className="card">
                                                <h5 className="card-header bg-primary text-white text-center">
                                                    Listes des employés
                                                </h5>
                                                <div className="card-body">
                                                <h3 className="card-title text-center"><Link to="#" onClick={openListModalPresent}>{employeTotal}</Link></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="card">
                                                <h5 className="card-header bg-success text-white text-center">
                                                    Employés Absents 
                                                </h5>
                                                <div className="card-body">
                                                <h3 className="card-title text-center"><Link to="#" onClick={openListModalAbsent}>{employeAbsents}</Link></h3>
                                                </div>
                                            </div>
                                        </div>
                                    
                                </div>
                            </section>
                            <div className="my-2 row border rounded p-2 bg-warning">

                            <div class="col-sm-2">
                                <label>Etat</label>
                                <select className="form-control" name="etat" onChange={(event)=>setEtat(event.target.value)}>
                                    <option value="1">Etat Général</option>
                                    <option value="2">Rapport (PDF)</option>
                                </select>
                            </div>
                            {etat == "1" &&
                            <div class="col-sm-3">
                                <label>Employés</label>
                                <Select options={options} onChange={handleChangeSelectOptionEmploye}/>
                            </div>
                            }

                            <div class="col-sm-3">
                                <label>Mois</label>
                                <select className="form-control" name="mois" onChange={(event)=>setMois(event.target.value)}>
                                    {moisList.map((mo)=>
                                         <option value={mo.value}>{mo.lib}</option>
                                    )}
                                  
                                </select>
                            </div>

                            <div class="col-sm-2">
                                <label>Années</label>
                                <select className="form-control" name="annees" onChange={(event)=>setAnnees(event.target.value)}>
                                   {
                                        anneeLists.map((annee,index)=>
                                            <option value={`${annee}`}>{annee}</option>
                                        )
                                   }
                                </select>
                            </div>

                            <div class="col-sm-2">
                                {etat == "1" && <button className="btn btn-success mt-4 form-control" onClick={searchWorkEmploye}>Valider</button> }
                                {etat == "2" &&
                                    mois != "00" ?
                                    <PDFDownloadLink document={<MyPdfDocument mois={moisList.find(mo => mo.value === mois).lib} annee={annees} dataPdf={pdfData}/>}
                                        fileName="Rapport.pdf"
                                        style={{
                                            textDecoration: "none",
                                            padding: "10px",
                                            color: "#FFF",
                                            backgroundColor: "#463F32",
                                            border: "1px solid #4a4a4a",
                                            //marginTop: "5px"
                                        }}  
                                        
                                        
                                    >
                                        {({ blob, url, loading, error }) =>
                                            loading ? "chargement du document..." : "Télécharger (Pdf)"
                                        }
                                    </PDFDownloadLink>
                                    : ""
                                }
                               
                              {/* <PDFGenerator /> */}
                            </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
        
        {nameComponent == "search" &&
            <>
                <div className=" mt-3 mx-3 ">
                    <div className="row">
                        <aside className="col-md-2">
                    
                        </aside>
                        <section className="col-md-9 ">
                            <div className="card">
                                <h5 className="card-header text-center">
                                    <b className="mx-3">{mois == "00" ? `Année ${annees}` : `Résultat du ${mois}-${annees}`}</b>
                                </h5>

                                {loading && searchEmployeList.length ==0 && <span className="text-center">Chargement en cours...</span>}

                                {!loading &&
                                    <div className="card-body ">
                                        {searchEmployeList.length != 0 &&
                                             <div className="my-2 row border rounded p-2 bg-tranparent">

                                             <section className="col-md-12">
                                                 <div className="row">
                                                         <div className="col-md-3">
                                                             {/* <div className="card">
                                                                 <h5 className="card-header bg-primary text-white">
                                                                     Enrolled Course
                                                                 </h5>
                                                                 <div className="card-body">
                                                                 <h3 className="card-title"><Link to="#">5</Link></h3>
                                                                 </div>
                                                             </div> */}
                                                         </div>
                                                         <div className="col-md-3">
                                                     {/*  <div className="card">
                                                                 <h5 className="card-header bg-success text-white">
                                                                     Favorite Courses
                                                                 </h5>
                                                                 <div className="card-body">
                                                                 <h3 className="card-title"><Link to="#">3</Link></h3>
                                                                 </div>
                                                             </div> */}
                                                         </div>
                                                         <div className="col-md-6">
                                                             <div className="card">
                                                                 <h5 className="card-header bg-info text-white">
                                                                     Statistiques 
                                                                 </h5>
                                                                 <div className="card-body">
                                                                 <h6>
                                                                     Absence Enregistrée : <b className="text-danger">{absEnregistre}</b>
                                                                 </h6>
                                                                 <h6>
                                                                     Nombre de retard : <span className="text-danger">{retardTotal}</span>
                                                                </h6>
                                                                  <h6>
                                                                     Heure de travail cumulé : <b className="text-danger">{heurCumule} Heures</b> 
                                                                 </h6> 
                                                               
                                                                 </div>
                                                             </div>
         
                                                         {/*  <input className="float-end mt-3 w-10" placeholder="Recherche..." /> */}
                                                         </div>
                                                 </div>
                                             </section>
                                         
                                             </div>
                                        }
                                           
                                    
                                    
                                        {Object.keys(searchEmployeList).map((date,i)=>(
                                        <>
                                    <span className="bg-danger text-white mb-0">{moment(date).format("dddd Do MMMM YYYY ")} </span>
                                    <div className="table-responsive">
                                        <table class="table table-bordered  rounded p-2 border-transparent ">
                                        
                                        <thead className="bg-dark text-white">
                                            <tr>
                                                {/* <th className="text-center">ID</th> */}
                                                <th className="text-center">Nom</th>
                                                <th className="text-center">Prénoms</th>
                                                <th className="text-center">Poste</th>
                                                <th className="text-center">Arrivé(Heure)</th>
                                                <th className="text-center">Depart(Heure)</th>
                                                <th className="text-center">Durée de travail </th>
                                                <th className="text-center">Statut</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                                {searchEmployeList[date].map((employe,index)=>
                                                    <tr>
                                                    {/* <td className="text-center">{index+1}</td> */}
                                                    <td className="text-center">{employe.employe?.nom}</td>
                                                    <td className="text-center">{employe.employe?.prenom}</td>
                                                    <td className="text-center">{employe.employe?.profession}</td>
                                                    <td className="text-center">{employe.arrive !== null ? moment(employe.arrive).format("HH:mm") :""}</td>
                                                    <td className="text-center">{employe.depart !== null ? moment(employe.depart).format("HH:mm") : ""}</td>
                                                    <td className="text-center"><b className="bg-info text-danger">{employe.dureeTravail | employe.dureeTravail == 0  ? `${employe.dureeTravail} Heures` : ""}</b></td>
                                                    <td className="text-center">
                                                    {employe.status ?
                                                        <b className="text-success">Présent</b>
                                                        :
                                                        <b className="text-danger">Absent {employe.justifAbsence ? <Link to={`/detail-absenceTo/${employe.employe?.id}/?employe=${employe.employe?.nom} ${employe.employe?.prenom}`}>(Justifiée )</Link> : <span>(Non justifiée)</span>}</b>
                                                    }
                                                    </td>

                                                    <td className="">
                                                        {!employe.status ? 
                                                                <>
                                                                     {!employe.justifAbsence && <button className="btn btn-primary btn-sm" onClick={()=>openModalJustif(index,employe.id,employe.employe?.id,employe.employe?.nom,employe.employe?.prenom)}>Justifier</button>}
                                                                    
                                                                </>
                                                               
                                                                    : ""
                                                        
                                                            
                                                        }
                                                        
                                                    </td>
                                                    
                                                </tr>
                                                )}
                                                        

                                            
                                                
                        
                                        
                                        
                                        </tbody>
                                        </table>
                                    </div>
                                        </>
                                    ))} 
                                    

                                    </div>
                                }

                                {loading && searchEmployeList.length ==0 && <b className="text-center">chargement en cours ...</b>}
                                {!loading && searchEmployeList.length ==0 && <b className="text-center">Aucun résultat trouvé.</b>}
                                

                            </div>

                        </section>
                    </div>
                </div>
            </>
        }
        
        
        {nameComponent == "journee" &&
            <>
            {dayOpen?.etat && 
                    <div className=" mt-3 mx-3">
                        <div className="row">
                            <aside className="col-md-2">
                           
                            </aside>
                            <section className="col-md-9">
                                <div className="card ">
                                    <h5 className="card-header text-center">
                                        <b className="mx-3">Journée en cours</b>
                                    </h5>
                                    <div className="card-body">

                                    <div className="my-2 row border rounded p-2 bg-tranparent ">

                                        <section className="col-md-12">
                                            <div className="row">
                                                    <div className="col-md-3">
                                                       
                                                    </div>
                                                    <div className="col-md-3">
                                                
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="card">
                                                            <h5 className="card-header bg-info text-white">
                                                                Statistiques 
                                                            </h5>
                                                            <div className="card-body">
                                                            <h6>
                                                                Employés arrivé : {employeArriveTotal}
                                                            </h6>
                                                            <h6>
                                                                Nombre de retard : <span className="text-danger">{employeRetard}</span>
                                                            </h6>
                                                            <h6>
                                                                Employés parti : {employePartiTotal}
                                                            </h6>
                                                           
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </section>

                                    </div>
                                        <div className="table-responsive">
                                            <table class="table table-bordered  rounded p-2 border-transparent">
                                            
                                            <thead className="bg-dark text-white">
                                                <tr>
                                                    <th className="text-center">ID</th>
                                                    <th className="text-center">Nom</th>
                                                    <th className="text-center">Prénoms</th>
                                                    <th className="text-center">Poste</th>
                                                    <th className="text-center">Arrivé (Heure)</th>
                                                    <th className="text-center">Départ (Heure)</th>
                                                    <th className="text-center">Retard</th>
                                                </tr>
                                            </thead>
                
                                            <tbody>

                                                        {listEmployeDays.map((employe,index)=>
                                                            <tr>
                                                            <td className="text-center">{index+1}</td>
                                                            <td className="text-center">{employe.employe?.nom}</td>
                                                            <td className="text-center">{employe.employe?.prenom}</td>
                                                            <td className="text-center">{employe.employe?.profession}</td>
                                                            <td className="text-center">
                                                                {employe.arrive !== null &&
                                                                <b className="text-success">{moment(employe.arrive).format("HH:mm:ss")}</b>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {employe.depart !== null &&
                                                                    <b className="text-danger">{moment(employe.depart).format("HH:mm:ss")}</b>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {employe.arrive !==null && employe.etatRetard && <b className="text-danger">OUI</b>}
                                                                {employe.arrive !==null && !employe.etatRetard && <b className="text-success">NON</b>}
                                                            </td>
                                                        </tr>
                                                        )}
                                                
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
            
                                
                                    
                                
                            </section>
                        </div>
                    </div>
            }

            {!dayOpen?.etat &&
                <div className=" mt-3 mx-3">
                   <div className="row">
                       <aside className="col-md-2">
                       </aside>
                       <section className="col-md-9">
                           <div className="card">
                               <h5 className="card-header text-center">
                                   <b className="mx-3">Aucune Journée Ouverte</b>
                               </h5>
                               <div className="card-body">

                                    <h4 className="text-center text-danger">Désolé il n' y a aucune journée ouverte en ce moment...</h4>
       
                               </div>
                           </div>
       
                         
                               
                         
                       </section>
                   </div>
                </div>
            }
              
            </>
        }


<div class="modal fade" id="openListPresent" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Liste des employés présents <b className="text-warning">{listemployePresents.length}</b></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body table-responsive-sm">

        {loadingList && <span className="text-center">Chargement en cours...</span>}

        {!loadingList && listemployePresents.length > 0 && 
             <table class="table table-bordered  rounded p-2 border-transparent">
                                        
             <thead className="bg-dark text-white">
                 <tr>
                     <th className="text-center">ID</th>
                     <th className="text-center">Nom</th>
                     <th className="text-center">Prénoms</th>
                     <th className="text-center">Poste</th>
                     <th className="text-center">Etat</th>
                      <th className="text-center">Action</th> 
                 </tr>
             </thead>
     
                 <tbody>
     
                         {listemployePresents.map((employe,index)=>
                             <tr>
                             <td className="text-center">{index+1}</td>
                             <td className="text-center">{employe?.nom}</td>
                             <td className="text-center">{employe?.prenom}</td>
                             <td className="text-center">{employe?.profession}</td>
                             <td className="text-center">
                             {employe.isAbidjan  ?
                                <>
                                    {employe.etatPresence && <b className="text-success">Présent</b>}
                                    {!employe.etatPresence && <b className="text-danger">Absent</b>}
                                </>
                                 
                                 :
                                 <b className="text-danger">Desactivé</b>
                             }
                             </td>
                              <td className="text-center">
                            

                            {employe.isAbidjan ? 
                                <>
                                    {employe.etatPresence && <button className="btn btn-sm btn-primary" title="Ajouter une absence"  onClick={()=>AddAbsenceTo(employe.nom,employe.prenom,employe.id)} ><i class="bi bi-card-checklist"></i></button>}
                               
                                    <button className="btn btn-sm btn-danger mx-1" title="Désactiver un employé" onClick={()=>DesactiveFunctionEmpl(employe.id)}><i class="bi bi-play-circle"></i></button>
                                </>
                                 :
                                 ""
                            }
                            </td> 
                             
                         </tr>
                         )}
                     
                 </tbody>
             </table>
        }

        {!loadingList && listemployePresents.length == 0 && <span className="text-center">Aucun employé présents</span>}
       
      </div>
 
    </div>
  </div>
</div>

<div class="modal fade" id="openListAbs" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Liste des employés Absents <b className="text-warning">{listEmployeAbsents.length}</b></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body table-responsive-sm">

        {loadingList && <span className="text-center">Chargement en cours...</span>}

        {!loadingList && listEmployeAbsents.length > 0 && 
             <table class="table table-bordered  rounded p-2 border-transparent">
                                        
             <thead className="bg-dark text-white">
                 <tr>
                     <th className="text-center">ID</th>
                     <th className="text-center">Nom</th>
                     <th className="text-center">Prénoms</th>
                     <th className="text-center">Poste</th>
                     <th className="text-center">Etat</th>
                    {/*  <th className="text-center">Action</th> */}
                 </tr>
             </thead>
     
                 <tbody>
     
                         {listEmployeAbsents.map((employe,index)=>
                             <tr>
                             <td className="text-center">{index+1}</td>
                             <td className="text-center">{employe?.nom}</td>
                             <td className="text-center">{employe?.prenom}</td>
                             <td className="text-center">{employe?.profession}</td>
                             <td className="text-center">
                             {employe.etatPresence ?
                                 <b className="text-success">Présent</b>
                                 :
                                 <b className="text-danger">Absent</b>
                             }
                             </td>
                           {/*   <td className="text-center">
                                 <button className="btn btn-sm btn-primary">M'absenter</button>
                             </td> */}
                             
                         </tr>
                         )}
                     
                 </tbody>
             </table>
        }

        {!loadingList && listEmployeAbsents.length == 0 && <span className="text-center">Aucun employé présents</span>}
       
      </div>
 
    </div>
  </div>
</div>

<div className="modal fade" id="modalChargement" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="modalLabelChargementRequete" aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
            
            <div className="modal-header">
                <h5 className="modal-title" id="modalLabelChargementRequete">Partientez...</h5>
                {/*    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>

                <div className="modal-body">
                
                <div className="row g-4 ">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8 text-center">
                            <img src="/gif/giphyquery.gif"  width="150px" />
                        </div>
                        <div className="col-sm-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>


<div className="modal fade" id={`absModal${modIdx}`}   tabindex="-1" role="dialog" aria-labelledby={`absModal${modIdx}Label`} aria-hidden="true">
    <div className="modal-dialog modal-xl">
        <div className="modal-content ">
            
            <div className="modal-header">
                <h5 className="modal-title" >{nameCplet}</h5>
            </div>

                <div className="modal-body">

                <div className="row">
                    <div class="col-6 my-2">
                        <label class="form-label">Motif</label>
                        <select name="absence" className="form-control" onChange={handleChange}>
                            <option value="" disabled selected>Choisir...</option>
                            {typeAbsences.map((abs,index)=>
                                <option value={abs.id}>{abs.libelle}</option>
                            )}
                            
                        </select>
                        {errorAbsence && <span className="text-danger">{errorAbsence}</span>}
                    </div>

                    

                    
                    
                    <div class="col-6 my-2">
                        <label class="form-label">Détail de l'absence</label>
                        <textarea class="form-control" rows="2" name="motif" onChange={handleChange} ></textarea>
                        {errorMotif && <span className="text-danger">{errorMotif}</span>}
                    </div>
                    {!loading && <button className="btn btn-primary my-2" onClick={()=>submitForm()}>Enregistrer</button>}
                    {loading && <button className="btn btn-primary my-2" disabled>En cours...</button>}
                    
                </div>
                
                </div>
        </div>
    </div>
</div>
       
        </>
      
    )
}

export default Dashboard;