import moment from "moment";
import Clock from "../Clock";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";
import Chart from "react-apexcharts";

const baseUrl = "http://pointageapp.akidompro.com/api";
//const baseUrl = "http://127.0.0.1:8000/api";
function StatistiquePage(){
    const navigate = useNavigate()
    const currentDate = new Date();
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_ucl'));
    const [client,setClients] = useState([]);

    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const [loading,setLoading] = useState(false);
    const [nameComponent,setNameComponent] = useState('');
    const [employes,setEmployes] = useState([]);
    const [mois,setMois] = useState('00');
    const [etat,setEtat] = useState('1');
    const [annees,setAnnees] = useState(`${year}`);
    const [emplValue,setEmplValue] = useState('');
    const [anneeLists,setAnneeLists] = useState([]);

    ///mes states
    const [seriesPies,setSeriesPies] = useState([]);
    const [labelsPies,setLabelsPies] = useState([]);

    const moisList = [
        {'value':'00','lib':'Tous'},
        {'value':'01','lib':'Janvier'},
        {'value':'02','lib':'Février'},
        {'value':'03','lib':'Mars'},
        {'value':'04','lib':'Avril'},
        {'value':'05','lib':'Mai'},
        {'value':'06','lib':'Juin'},
        {'value':'07','lib':'Juillet'},
        {'value':'08','lib':'Aôut'},
        {'value':'09','lib':'Septembre'},
        {'value':'10','lib':'Octobre'},
        {'value':'11','lib':'Novembre'},
        {'value':'12','lib':'Décembre'},
    ]

    const [stateByMonth,setStateByMonth] = useState({series: [], labels: []});
    const [stateCompareWork,setStateCompareWork] = useState({series: [],serieTwo: [],serieFour: [], labels: []})
    const [stateCompareWork1,setStateCompareWork1] = useState({ series: [], labels: [] });

    useEffect(()=>{

        if(isAuthToken == null){
            localStorage.removeItem('_token_ucl');
            navigate('/auth/');
        }
        if(isAuthToken !== null){
            try {
                axios.get(baseUrl+'/users-clients/?token='+isAuthToken,{
                    headers:{
                        'Content-Type':'application/json'
                    },
                    credentials:'include'
                }).then((resp)=>{
                    if(resp.data.failed == true){
                        setIsAuthToken(null);
                        setClients(null);
                        localStorage.removeItem('_token_ucl');
                        navigate('/auth/');
                    }
                    setClients(resp.data[0]);
                })
            } catch (error) {
                console.log(error);
            }
        }

        try {
            axios.get(baseUrl+'/employes-list/?all').then((resp)=>{
                setEmployes(resp.data)
            })
        } catch (error) {
            console.log(error);
        } 

        functAnneeList();

    },[]);


    function functAnneeList(){
        
        for (let i = year; i >= year - 10; i--) {
             anneeLists.push(`${i}`)
        }

    }

    const logout = ()=>{
        setIsAuthToken(null);
        setClients(null);
        localStorage.removeItem('_token_ucl');
        navigate('/auth/');
    }

    const StatistiqueGetFunction=()=>{
        if(emplValue == ""){
            try {
                axios.get(baseUrl+'/state-absence-plus-and-presence-plus/?annee='+annees).then((resp)=>{
                    const labelsArray = Object.keys(resp.data.labelTypeAbs).map(key => resp.data.labelTypeAbs[key]);
                    const labelMoisArr = Object.keys(resp.data.moisLabels).map(key => resp.data.moisLabels[key])
                    

                    setStateCompareWork1(
                        {
                            series: Object.values(resp.data.seriesTypeAbs),
                            labels: labelsArray.map((label)=>label.label),
                        }
                    );

                    setStateByMonth({
                        series: Object.values(resp.data.valueSeries),
                        labels: labelMoisArr.map((label)=>label.mois)
                    })

                  
                })
            } catch (error) {
                console.log(error);
            }
            setNameComponent('tousChart');
        }else{
            axios.get(baseUrl+'/state-absence-plus-and-presence-plus/?year='+annees+'&employe='+emplValue).then((resp)=>{
                const labelMoisArr = Object.keys(resp.data.moisLabels).map(key => resp.data.moisLabels[key])
                
                setStateCompareWork({
                    series: Object.values(resp.data.absenceValues),
                    serieTwo: Object.values(resp.data.cumulWorks),
                    serieFour: Object.values(resp.data.retardValues),
                    labels: labelMoisArr.map((label)=>label.mois)
                })
            })
            setNameComponent('emplChart');
        }
    }

    const options1 = {
        colors: ['#606c38', '#fefae0','#fb5607','#000000','#e3d5ca','#4361ee','#9a031e','#ffe5ec'],
        labels: stateCompareWork1.labels,
        legend: {
        show: true,
        position: 'bottom',
        },
    }

    const options2 = {
        
            colors: ['#D9534F', '#66DA26', '#546E7A', '#E91E63', '#FF9800','#606c38','#14213d','#ffbe0b','#ef233c','#80ed99','#e9ff70','#f5dfbb'],
            
            xaxis: {
                categories: stateByMonth.labels
            }
            
    }

    const options3 = {
        
        colors: ['#546E7A', '#E91E63', '#66DA26'],
        
        xaxis: {
            categories: stateCompareWork.labels
        }
        
}

    const serie2 = [
       
        {
            name: "Absence",
            data: stateByMonth.series.map((serie)=>serie.value)
        },
]

const serieThree = [
       
    {
        name: "Absence (Jours)",
        data: stateCompareWork.series.map((serie)=>serie.value)
    },
    {
        name: "Heures cumulée (Heures)",
        data: stateCompareWork.serieTwo.map((heure)=>heure.value)
    }, 
    {
        name: "Retard (Nombre de retard)",
        data: stateCompareWork.serieFour.map((retard)=>retard.value)
    }, 
]
    
    return (
                <>
              <div className=" mt-4 mx-3">
            <div className="row">
                <aside className="col-md-2"> 
                 <div className="card bg-warning">
                    <div className="list-group list-group-flush ">
                        <Link className="list-group-item list-group-item-action text-primary" to={`/dashboard/`}>Accueil</Link> 
                         <Link className="list-group-item list-group-item-action text-primary" to={`/list-absence/`}>Gestion des Absences</Link> 
                        <Link className="list-group-item list-group-item-action text-primary" to={`/statistique-generale/`}>Statistique Générales</Link>
                         <button className="list-group-item list-group-item-action text-primary " onClick={()=>logout()}>Se Deconnecter</button> 
                    </div>
                </div> 
                </aside>
                <section className="col-md-9">
                    <div className="card">
                        <h5 className="card-header text-center">
                            <b className="mx-3">Page statistique Générales</b>
                        </h5>
                        <div className="card-body">

                            <div className="my-2 row border rounded p-2 bg-warning">

                            <div class="col-sm-7">
                                <label>Employés</label>
                                <select className="form-control" name="emplValue" onChange={(event)=>setEmplValue(event.target.value)}>
                                    <option value="" selected>Tous les employés</option>
                                    {employes.map((empl,index)=>
                                        <option value={empl.id}>{empl.nom} {empl.prenom}</option>
                                    )}
                                </select>
                            </div>

                            <div class="col-sm-3">
                                <label>Années</label>
                                <select className="form-control" name="annees" onChange={(event)=>setAnnees(event.target.value)}>
                                {
                                        anneeLists.map((annee,index)=>
                                            <option value={`${annee}`}>{annee}</option>
                                        )
                                   }
                                </select>
                            </div>

                            <div class="col-sm-2">
                               <button className="btn btn-success mt-4 form-control" onClick={()=>StatistiqueGetFunction()}>Valider</button>
                            </div>

                            </div>
                            <hr />
                            <div className="row my-2">
                                {nameComponent == "tousChart" &&
                                    <>
                                         <div className="col-10">
                                                <h4 className="text-center"><u>Nombre d'absence par mois</u></h4>
                                            <Chart
                                                options={options2}
                                                series={serie2}
                                                type="bar"
                                                width="700"
                                                />
                                            </div>

                                         
                                            <div className="col-10 my-3">
                                                <h4 className="text-center"><u>Classe par type d'absences {annees}</u></h4>
                                            <Chart
                                                options={options1}
                                                series={stateCompareWork1.series.map((serie)=>serie.value)}
                                                type="donut"
                                                width="700"
                                                
                                                />
                                            </div>
                                    </>
                                }

                                {nameComponent == "emplChart" &&
                                    <div className="col-10">
                                            <h4 className="text-center"><u>Nombre d'absence et d'heures de travail de chaque employé  par mois</u></h4>
                                        <Chart
                                            options={options3}
                                            series={serieThree}
                                            type="bar"
                                            width="700"
                                            />
                                        </div>

                                        
                                }
                               

                                
                            </div>
                        </div>
                    </div>

                    
                </section>
            </div>
        </div>
        
     
        
        
  




       
        </>
    ) 
}

export default StatistiquePage;