import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const baseUrl = "http://pointageapp.akidompro.com/api";
function Login(){
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_ucl'));
    const [msgerrorAlert,setMsgerrorAlert] = useState('');
    const navigate = useNavigate();

    const [loginData,setLoginData] = useState({
        'email':'',
        'password':''
    });

    useEffect(()=>{
        if(isAuthToken){
            navigate('/dashboard/');
          }
    },[isAuthToken])

    const handleChange=(event)=>{
        setLoginData({
            ...loginData,
            [event.target.name] : event.target.value
        });
    }

    const submitLogin=()=>{
        setMsgerrorAlert('');
        if(loginData.email !== "" && loginData.password !==""){
            const _formData = new FormData();
            _formData.append('email',loginData.email);
            _formData.append('password',loginData.password);

            try {
                axios.post(baseUrl+'/login/',_formData).then((resp)=>{
                    if(resp.data.bool == true){
                        localStorage.setItem('_token_ucl',resp.data.token);
                        navigate('/dashboard/');
                    }else{
                      setMsgerrorAlert(resp.data.msg);
                    }
                })
            } catch (error) {
                console.log(error);
            }

        }
    }


    return (
    
        <html lang="en">
        <head>
            <title>Login</title>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

            <link href="https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap" rel="stylesheet" />

            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
            
            <link rel="stylesheet" href="/css/style.css" />

            </head>
            <body>
            <section class="ftco-section">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-6 text-center mb-5">
                            <h2 class="heading-section">ADMINISTRATION</h2>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-6 col-lg-5">
                        {msgerrorAlert !=="" &&
                            <div class="alert alert-danger" role="alert">
                            <h4 class="alert-heading">Attention !</h4>
                            <p>{msgerrorAlert}</p>
                            </div>
                        }
                            <div class="login-wrap p-4 p-md-5">
                        <div class="icon d-flex align-items-center justify-content-center">
                            <span class="fa fa-user-o"></span>
                        </div>
                        <h3 class="text-center mb-4">BIENVENU !</h3>
                               
                            <div class="form-group">
                                <input type="email" class="form-control rounded-left" onChange={handleChange} name="email" placeholder="Votre e-mail" required />
                            </div>
                        <div class="form-group d-flex">
                        <input type="password" class="form-control rounded-left" onChange={handleChange} name="password" placeholder="Votre mot de passe" required />
                        </div>
                     {/*    <div class="form-group d-md-flex">
                            <div class="w-50">
                                <label class="checkbox-wrap checkbox-primary">Remember Me
                                            <input type="checkbox" checked />
                                            <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="w-50 text-md-right">
                                            <a href="#">Forgot Password</a>
                                        </div>
                        </div> */}
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary rounded submit p-3 px-5" onClick={submitLogin}>Se connecter</button>
                        </div>
                   
                    </div>
                        </div>
                    </div>
                </div>
            </section>

            <script src="/js/jquery.min.js"></script>
        <script src="/js/popper.js"></script>
        <script src="/js/bootstrap.min.js"></script>
        <script src="/js/main.js"></script>

            </body>
        </html>


    )
}

export default Login;

