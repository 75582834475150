import { Link } from "react-router-dom";
import Clock from "../Clock";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";


const baseUrl = "http://pointageapp.akidompro.com/api";
//const baseUrl = "http://127.0.0.1:8000/api";

function PointagePage(){
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

   const [dateDay,setDateDay] = useState(formattedDate);
   const [journee,setJournee] = useState([]);
   const [listEmployeDays,setListEmployeDays] = useState([]);
   const [loadingOpen,setLoadingOpen] = useState(false);
   const [loading,setLoading] = useState(false);
   const [loadingArrive,setLoadingArrive] = useState(false);
   const [loadingDepart,setLoadingDepart] = useState(false);
   const [indexId,setIndexId] = useState(0);
   const [indexIdDepart,setIndexIdDepart] = useState(0);
   const [visiteurData,setVisiteurData] = useState({
    'nomComplet' :'', 
    'phone':'',
    'motif':''
   });
   const [nameComponent,setNameComponent] = useState('employe');
   const [clotureData,setClotureData] = useState({
    'code':''
   });
   const [identifiantPt,setIdentifiantPt] = useState('')

   const [loadingCloture,setLoadingCloture] = useState(false);
   const [searchValue,setSearchValue] = useState('');
   //const [timeUntilTarget,setTimeUntilTarget] = useState(0);
   const [requestInterval, setRequestInterval] = useState(null);

   
   useEffect(()=>{
        //Requêtes programmée 
        const now = new Date();
        const targetTime = new Date(now);
        targetTime.setHours(23, 10, 0, 0);
        //const timeUntilTarget = 0 ;
        const timeUntilTarget = targetTime - now;
         
        if (timeUntilTarget < 0) {
            targetTime.setDate(targetTime.getDate() + 1);
            //timeUntilTarget = targetTime - now;
        }

        const initialTimer = setTimeout(() => {

            closeProgrammeDays();

            const interval = setInterval(() => {
                closeProgrammeDays();
            }, 24 * 60 * 60 * 1000);
            
            setRequestInterval(interval);
        }, timeUntilTarget);
      

        if(dateDay){
            try {
                axios.get(baseUrl+'/journee-ouverte-list/?active='+dateDay).then((resp)=>{
                    setJournee(resp.data[0]);
                })

                axios.get(baseUrl+'/pointage-day-list/?days='+dateDay).then((res)=>{
                                    
                    setListEmployeDays(res.data);
                })
            } catch (error) {
                
            }
        }

        return () => {
            clearTimeout(initialTimer);
            clearInterval(requestInterval);
        };
           
   },[dateDay]);

   const handleChangeCloture=(event)=>{
        setClotureData({
            ...clotureData,
            [event.target.name]:event.target.value
        });
   }

   const OpenDayFunction=()=>{
    const _formData = new FormData();
    _formData.append('etat',1);

    setLoadingOpen(true);
        try {
            axios.post(baseUrl+'/open-journe/',_formData).then((resp)=>{
                if(resp.data.bool){
                    Swal.fire({
                        title: 'FELICITATION',
                        text: 'La journée est maintenant ouverte.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK'
                      }).then((result) => {
                        if (result.isConfirmed) {
                        
                                window.location.reload();
                                        
                                    
                        
                        }
                      }); 

                }else{
                    Swal.fire({
                        title: 'Ooops !',
                        text: resp.data.msg,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK'
                      });
                      setLoadingOpen(false);
                }
            })
        } catch (error) {
            console.log(error);
        }
   }

   const ArriverFunction=(employeId,idx)=>{


    if(identifiantPt !==''){
        const _formData = new FormData();
        _formData.append("journee",journee.date);
        _formData.append("employe",employeId);
        _formData.append("identifiantPt",identifiantPt);
        setLoading(true);
        setLoadingArrive(true)
        try {
            axios.post(baseUrl+'/mon-arriver-au-boulot/',_formData).then((resp)=>{
                    if(resp.data.bool){
                        axios.get(baseUrl+'/pointage-day-list/?days='+journee.date).then((res)=>{              
                            setListEmployeDays(res.data);
                        });
                        setLoadingArrive(false); 
                        setLoading(false);
                        window.$(`#modalArriveCode${idx}`).modal('hide');
                        setIdentifiantPt('');
                    }else{
                        Swal.fire({
                            title: 'ATTENTION !',
                            text: resp.data.msg,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK'
                        });
                        setLoadingArrive(false); 
                        setLoading(false);
                        setIdentifiantPt('');
                    }
                
            });
        } catch (error) {
            console.log(error);
        }
    
        setIndexId(employeId);
    }

   }

   const DepartFunction=(employeId,idx)=>{



        if(identifiantPt !==''){
            const _formData = new FormData();
            _formData.append("journee",journee.date);
            _formData.append("employe",employeId);
            _formData.append("identifiantPt",identifiantPt);

            setLoading(true);
            setLoadingDepart(true)
            try {
                axios.post(baseUrl+'/mon-depart-du-boulot/',_formData).then((resp)=>{
                    if(resp.data.bool){
                        axios.get(baseUrl+'/pointage-day-list/?days='+journee.date).then((res)=>{              
                            setListEmployeDays(res.data);
                        });
                        setLoadingDepart(false); 
                        setLoading(false);
                        window.$(`#modalDepartCode${idx}`).modal('hide');
                        setIdentifiantPt('');
                    }else{
                        Swal.fire({
                            title: 'ATTENTION !',
                            text: resp.data.msg,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK'
                          });
                          setLoadingDepart(false); 
                          setLoading(false);
                          setIdentifiantPt('');
                    }
                   
                    
                });
            } catch (error) {
                console.log(error);
            }
        
            setIndexIdDepart(employeId);
        }


   }

   const openModalCloture=()=>{
        window.$(`#modalCloture`).modal('show');
   }

   const submitCloture=()=>{
        const _formData = new FormData();

        if(clotureData.code !==""){
            _formData.append('code',clotureData.code);
            _formData.append('journee',journee.date);
            setLoadingCloture(true);
            try {
                axios.post(baseUrl+'/cloture-day-click/',_formData).then((resp)=>{
                    if(resp.data.bool){
                        Swal.fire({
                            title: 'FELICITATION',
                            text: 'La journée est maintenant fermée .',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK'
                          }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                          }); 
                    }else{
                        Swal.fire({
                            title: 'Ooops !',
                            text: resp.data.msg,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK'
                        });
                        setLoadingCloture(false);
                    }
                })
            } catch (error) {
                console.log(error);
            }
        }
        
   }

   const searchName=(event)=>{
    setSearchValue(event.target.value);
    if (journee?.etat){
        try {
            axios.get(baseUrl+'/pointage-day-list/?date='+journee.date+'&values='+event.target.value).then((resp)=>{
                setListEmployeDays(resp.data);
            })
        } catch (error) {
            console.log(error);
        }
    }else{
        listEmployeDays.length = 0 ;
    }
   
   }

   const closeProgrammeDays=()=>{
    if (journee?.etat ){
        const _formData = new FormData();
        _formData.append('journee',journee.date);
        try {
            axios.post(baseUrl+'/cloture-day-automatics/',_formData).then((resp)=>{
                if(resp.data.bool){
                    window.location.reload();
                }else{
                    Swal.fire({
                        title: 'Ooops !',
                        text: resp.data.msg,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK'
                    });
                   
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
   
   }





    return (
        <>
            {/* header ou en tete */}
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container text-center">
                <Link className="navbar-brand" to="/">APPLICATION DE PRESENCE</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                {/* <form class="d-flex">
                        <input class="form-control me-2" name="search"  type="search" placeholder="Search by course title" aria-label="Search" />
                        <button class="btn btn-warning"  type="button" >Search</button>
                    </form> */}
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div className="navbar-nav ms-auto">
                                {/* <Link className="nav-link" >Ajouter visiteur</Link> */}
                            </div>
                    </div>
                </div>
            </nav>
            {/* end header */}
            <div className="mt-4 mx-3 ">
            <div className="row ">
               
                <section className="col-md-12 ">
                    <div className="card ">
                        <h5 className="card-header text-center">
                            <b className="mx-3">{moment(formattedDate).format("dddd,Do MMMM ")}</b>
                            <span className="text-center"><Clock /></span>
                        </h5>
                        <div className="card-body">
                            <div className="mb-3 row">
                                {journee?.etat ?
                                    
                                    <button className="col-sm-2 btn btn-primary"  disabled>Journée en cours</button> 
                                    :
                                    <>
                                    {loadingOpen && <button className="col-sm-2 btn btn-primary"  >Chargement...</button> }
                                    {!loadingOpen &&<button className="col-sm-2 btn btn-primary" onClick={()=>OpenDayFunction()} >Nouvelle Journée</button> }
                                    </>

                                }
                                 
                                 <button className={journee?.etat ? "col-sm-3 btn btn-warning mx-1" : "col-sm-3 btn btn-warning mx-1 disabled" } onClick={()=>openModalCloture()}>Clôture de journée</button> 
                              {/*  <div className="col-sm-6">
                                <input type="search" className="form-control border" name="password" placeholder="Saisir votre Nom.."  id="inputPassword" onChange={searchName} />
                                </div> */}
                            </div>

                            <hr />
                            {searchValue !=="" && <h5 className="text-dark">Recherche: "<span className="text-primary">{searchValue}</span>" <span className="float-end">Total : <b className="text-danger">{listEmployeDays.length}</b></span></h5> }
                        <div className="table-responsive">
                        <table class="table table-bordered border-transparent ">
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th className="text-center">Nom</th>
                                    <th className="text-center">Prénoms</th>
                                    <th className="text-center">Poste</th>
                                    <th className="text-center">Arrivé</th>
                                    <th className="text-center">Départ</th>
                                    <th className="text-center">Retard</th>
                                </tr>
                            </thead>

                            <tbody>
                                 {journee?.etat &&
                                 
                                    listEmployeDays.map((employe,index)=>
                                        <tr>
                                            <td className="text-center">{index+1}</td>
                                            <td className="text-center">{employe.employe?.nom}</td>
                                            <td className="text-center">{employe.employe?.prenom}</td>
                                            <td className="text-center">{employe.employe?.profession}</td>
                                            <td className="text-center">
                                                
                                                  {/*   <button className="btn btn-success btn-sm" disabled>En cours...</button> */}
                                                

                                                
                                                    {employe.arrive == null && <button className={employe.employe?.id == indexId && loadingArrive && employe.arrive == null ? "btn btn-success btn-sm disabled" : "btn btn-success btn-sm " } data-bs-toggle="modal" data-bs-target={`#modalArriveCode${index}`}>{employe.employe?.id == indexId && loadingArrive && employe.arrive == null ? "En cours..." : "Mon arrivé" } </button> }
                                                    <div class="modal fade" id={`modalArriveCode${index}`} tabindex="-1" aria-labelledby="modalLabelCreateAlbum" aria-hidden="true">
                                                        <div class="modal-dialog modal-md modal-dialog-centered">
                                                            <div class="modal-content">
                                                            
                                                                <div class="modal-header">
                                                                    <span class="modal-title" id="modalLabelCreateAlbum"><strong>{employe.employe?.nom} {employe.employe?.prenom} (Arrivé)</strong></span>
                                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                            
                                                                <div class="modal-body">
                                                                
                                                                    <div class="col-lg-12 mx-auto mt-3">
                                                                        <input type="text" className="form-control" placeholder="Saisir votre Identifiant"  name="identifiantPointage" onChange={(event)=>setIdentifiantPt(event.target.value)} value={identifiantPt}/>
                                                                    </div>
                                                                
                                                                    
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal"> Fermer</button>
                                                                    {loading &&<button type="button" class="btn btn-success" disabled>Chargement...</button>}
                                                                    {!loading &&<button type="button" class="btn btn-success" onClick={()=>ArriverFunction(employe.employe?.id,index)}>Valider</button>}
                                                                    
                                                                
                                                            
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                

                                                  {employe.arrive !== null &&
                                                    <button className="btn btn-success btn-sm"  disabled>Mon arrivé</button>
                                                }  

                                              
                                                
                                            </td>
                                            <td className="text-center">
                                             {/*    {employe.arrive == null && employe.dapart == null && <button className="btn btn-danger btn-sm" disabled>Mon depart</button>} */}

                                              
                                                    {employe.depart == null &&<button className={employe.employe?.id == indexIdDepart && loadingDepart && employe.depart == null ? "btn btn-danger btn-sm disabled" : "btn btn-danger btn-sm" }  data-bs-toggle="modal" data-bs-target={`#modalDepartCode${index}`}>{employe.employe?.id == indexIdDepart && loadingDepart && employe.depart == null ? "En cours..." : "Mon Depart" }</button>}
                                                    <div class="modal fade" id={`modalDepartCode${index}`} tabindex="-1" aria-labelledby="modalLabelCreateAlbum" aria-hidden="true">
                                                        <div class="modal-dialog modal-md modal-dialog-centered">
                                                            <div class="modal-content">
                                                            
                                                                <div class="modal-header">
                                                                    <span class="modal-title" id="modalLabelCreateAlbum"><strong>{employe.employe?.nom} {employe.employe?.prenom} (Depart)</strong></span>
                                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                            
                                                                <div class="modal-body">
                                                                
                                                                    <div class="col-lg-12 mx-auto mt-3">
                                                                        <input type="text" className="form-control" placeholder="Saisir votre Identifiant"  name="identifiantPointage" onChange={(event)=>setIdentifiantPt(event.target.value)} value={identifiantPt}/>
                                                                    </div>
                                                                
                                                                    
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal"> Fermer</button>
                                                                    {loading &&<button type="button" class="btn btn-success" disabled>Chargement...</button>}
                                                                    {!loading &&<button type="button" class="btn btn-success" onClick={()=>DepartFunction(employe.employe?.id,index)}>Valider</button>}
                                                                    
                                                                
                                                            
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                               
                                                    {employe?.depart !== null &&
                                                            <button className="btn btn-danger btn-sm"  disabled>Mon Depart</button>
                                                    }  
                                            </td>

                                            <td className="text-center">
                                                {employe.arrive !==null && employe.etatRetard && <b className="text-danger">OUI</b>}
                                                {employe.arrive !==null && !employe.etatRetard && <b className="text-success">NON</b>}
                                            </td>
                                        </tr>
                                    )
                                   
                                } 

                                {!journee?.etat &&
                                 <tr className="text-center">
                                       
                                       <b className="text-danger"> Liste Vide</b>
                                       
                                 </tr>
                                      
                                }
                               
                            </tbody>
                            </table>
                                </div>
                        </div>
                    </div>
                </section>
            </div>
            </div>

        {/* Verification cloture */}
        <div class="modal fade" id="modalCloture" tabindex="-1" aria-labelledby="modalLabelCreateAlbum" aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                    
                        <div class="modal-header">
                            <span class="modal-title" id="modalLabelCreateAlbum"><strong>Code de clôture</strong></span>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    
                        <div class="modal-body">
                        
                            <div class="col-lg-12 mx-auto mt-3">
                                <input className="form-control" placeholder="Saisir le code de clôture"  name="code" onChange={handleChangeCloture}/>
                            </div>
                        
                            
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal"> Fermer</button>
                            {loadingCloture &&<button type="button" class="btn btn-success" disabled>Chargement...</button>}
                            {!loadingCloture &&<button type="button" class="btn btn-success" onClick={submitCloture}>Valider</button>}
                            
                           
                    
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PointagePage;