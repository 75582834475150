import logo from './logo.svg';
/* import './App.css'; */

import moment from 'moment';
import 'moment/locale/fr';
import Main from './components/Main';

moment.locale('fr')

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
