import React from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';


const borderColor = '#90e5fc'
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft:20,
        paddingRight:20,
        lineHeight: 1.5,
        flexDirection: 'column',
        //backgroundColor: 'red'
  },

  titleContainer : {
    flexDirection: 'row',
    marginTop: 5,
    alignItems :'center',
    backgroundColor:'red',
    textAlign:'center'
  },

  reportTitle:{
    //letterSpacing: 2,
    fontSize: 18,
    textTransform: 'uppercase',
    textDecoration:'underline',
    textAlign :'center',
    //border : '3',
    paddingTop :'5',
    paddingLeft:10,
    paddingRight:10,

},

tableContainer: {
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 24,
  borderWidth: 1,
  borderColor: '#bff0fd',
},

container: {
  flexDirection: 'row',
  borderBottomColor: '#bff0fd',
  backgroundColor: '#bff0fd',
  borderBottomWidth: 1,
  alignItems: 'center',
  height: 24,
  textAlign: 'center',
  fontStyle: 'bold',
  flexGrow: 1,
},
nom: {
  width: '40%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
},
/* fonction: {
  width: '15%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
}, */
retard: {
  width: '15%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
},


abs: {
  width: '30%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
},

cumul: {
  width: '15%'
},

row: {
  flexDirection: 'row',
  borderBottomColor: '#bff0fd',
  borderBottomWidth: 1,
  alignItems: 'center',
  height: 24,
  fontStyle: 'bold',
},

nomRow: {
  width: '40%',
  textAlign: 'left',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingLeft: 8,
},

/* fonctionRow: {
  width: '15%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  textAlign: 'right',
  paddingRight: 8,
}, */
retardRow: {
  width: '15%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  textAlign: 'right',
  paddingRight: 8,
},


absRow: {
  width: '30%',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  textAlign: 'right',
  paddingRight: 8,
},

cumulRow: {
  width: '15%',
  textAlign: 'right',
  paddingRight: 8,
},


titleContainerFooter:{
  flexDirection: 'row',
  alignItems : 'flex-end',
  marginTop: 12
},
reportTitleFooter:{
  fontSize: 8,
  textAlign: 'center',
  textTransform: 'uppercase',
  alignItems : 'flex-end',
  marginTop: 12
}


});





/* function PDFGenerator() {
  return (
    <a className='btn btn-success'>
      <PDFDownloadLink document={<MyDocument />} fileName="example.pdf">
        {({ blob, url, loading, error }) =>
          loading ? 'Chargement du PDF...' : 'Télécharger le PDF'
        }
      </PDFDownloadLink>
    </a>
  );
} */

const MyPdfDocument = ({mois,annee,dataPdf}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      
        <Text style={styles.reportTitle}>RAPPORT DU MOIS DE {mois} {annee}</Text>
        <View style={styles.tableContainer}>
            <View style={styles.container}>
                <Text style={styles.nom}>Nom complet</Text>
                <Text style={styles.retard}>Nbe Retard</Text>
                <Text style={styles.abs}>Absence</Text>
                <Text style={styles.cumul}>Heure totale</Text>
            </View>

            {dataPdf ?
              dataPdf.map((pdf,index)=>
              <View style={styles.row} >
                  <Text style={styles.nomRow}>{pdf.noms}</Text>
                  <Text style={styles.retardRow}>{pdf.rtard}</Text>
                  <Text style={styles.absRow}>
                  <Text >justifiée : {pdf.absence_j} </Text>|
                  <Text style={{color:'red'}}> non justifiée : {pdf.absence_n} </Text>
                  </Text>
                  <Text style={styles.cumulRow}>{pdf.theurs} / 173,33 H</Text>
            </View>
              )
               : ""
            }
            
            
        </View>
        <Text style={styles.reportTitleFooter}>Par AGROMAP TECHNOLOY @2023</Text>
       {/*  <View style={styles.titleContainerFooter}>
        
    </View> */}
    </Page>
  </Document>
);

export default MyPdfDocument;
