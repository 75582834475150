import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const baseUrl = "http://pointageapp.akidompro.com/api";
//const baseUrl = "http://127.0.0.1:8000/api";
function AbsencePage(){
    const params = new URLSearchParams(window.location.search);
    const nom = params.get('nom-complet');
    const idEmpl = params.get('empl');
    const navigate = useNavigate();
    
    const [typeAbsences,setTypeAbsences] = useState([]);
    const [absenceData,setAbsenceData] = useState({
        'absence':'',
        'motif':'',
        'dureeAbsence':'1',
        'tempsAbsence':'',
        'dateDepart':'', 
        'dateRetoutProvisoire':'',
    });

    const [errorAbsence,setErrorAbsence] = useState('');
    const [errorMotif,setErrorMotif] = useState('');
    const [errorDuree,setErrorDuree] = useState('');
    const [errorTemps,setErrorTemps] = useState('');
    const [errorDepart,setErrorDepart] = useState('');
    const [errorRetour,setErrorRetour] = useState('');

    const [loading,setLoading] = useState(false);

    
 
    useEffect(()=>{
        try {
            axios.get(baseUrl+'/types-absences/').then((resp)=>{
                setTypeAbsences(resp.data)
            })
        } catch (error) {
            console.log(error);
        }
    },[])

    const handleChange=(event)=>{
        setAbsenceData({
            ...absenceData,
            [event.target.name] : event.target.value
        })
    }

    const submitForm=()=>{
        const _formData = new FormData();
        setErrorAbsence('');
        setErrorMotif('');
        setErrorDuree('');
        setErrorTemps('');
        setErrorDepart('');
        setErrorRetour('');
        

        setLoading(true);
        if(absenceData.absence == ""){setErrorAbsence('Ce champs est obligatoire')}
        if(absenceData.motif == ""){setErrorMotif('Ce champs est obligatoire')}
        if(absenceData.dureeAbsence == ""){setErrorDuree('Ce champs est obligatoire')}
        if(absenceData.tempsAbsence == ""){setErrorTemps('Ce champs est obligatoire')}
        if(absenceData.dateDepart == ""){setErrorDepart('Ce champs est obligatoire')}
        if(absenceData.dateRetoutProvisoire == ""){setErrorRetour('Ce champs est obligatoire')}

        if (absenceData.absence !== "" && absenceData.motif !== "" && absenceData.dureeAbsence !== "" && absenceData.tempsAbsence !== "" && absenceData.dateDepart !== "" && absenceData.dateRetoutProvisoire !== ""){
            _formData.append('absence',absenceData.absence);
            _formData.append('motif',absenceData.motif);
            _formData.append('dureeAbsence',absenceData.dureeAbsence);
            _formData.append('tempsAbsence',absenceData.tempsAbsence);
            _formData.append('dateDepart',absenceData.dateDepart);
            _formData.append('dateRetoutProvisoire',absenceData.dateRetoutProvisoire);
            _formData.append('employeId',idEmpl);
            try {
                axios.post(baseUrl+'/save-absence/',_formData).then((resp)=>{
                    setLoading(false);
                    navigate(`/dashboard/`);
                })
            } catch (error) {
                console.log(error);
            }
        }
        setLoading(false);
    }
    
    return (
        <div className=" mt-4 mx-3">
                <div className="card container">
                    <div className="card-header">
                        <h6>Enregistrement d'une absence de <i className="text-success">{nom}</i></h6>
                    </div>
                    <div className="card-body">
                    <div className="row">
                    <div class="col-6 my-2">
                        <label class="form-label">Motif</label>
                        <select name="absence" className="form-control" onChange={handleChange}>
                            <option value="" disabled selected>Choisir...</option>
                            {typeAbsences.map((abs,index)=>
                                <option value={abs.id}>{abs.libelle}</option>
                            )}
                            
                        </select>
                        {errorAbsence && <span className="text-danger">{errorAbsence}</span>}
                    </div>

                    <div class="col-3 my-2">
                        <label class="form-label">Temps</label>
                        <select name="tempsAbsence" className="form-control" onChange={handleChange}>
                            <option value="" disabled selected>Choisir...</option>
                            <option value="Demi-Journée">Demi-Journée</option>
                            <option value="Journée">Journée</option>
                            <option value="Semaine">Semaine</option>
                            <option value="Mois">Mois</option>
                            <option value="Année">Année</option>
                        </select>
                        {errorTemps && <span className="text-danger">{errorTemps}</span>}
                    </div>

                    <div class="col-3 my-2">
                        <label class="form-label">Durée</label>
                        <input type="number" name="dureeAbsence" className="form-control"  onChange={handleChange} value={absenceData.dureeAbsence}/>
                        {errorDuree && <span className="text-danger">{errorDuree}</span>}
                    </div>
                   
                    <div class="col-3 my-2">
                        <label class="form-label">Date de départ</label>
                        <input type="date" name="dateDepart" className="form-control"  onChange={handleChange}/>
                        {errorDepart && <span className="text-danger">{errorDepart}</span>}
                    </div>

                    <div class="col-3 my-2">
                        <label class="form-label">Date de Retour connue</label>
                        <input type="date" name="dateRetoutProvisoire" className="form-control"  onChange={handleChange} />
                        {errorRetour && <span className="text-danger">{errorRetour}</span>}
                    </div>
                    

                    
                    
                    <div class="col-6 my-2">
                        <label class="form-label">Détail de l'absence</label>
                        <textarea class="form-control" rows="2" name="motif" onChange={handleChange} ></textarea>
                        {errorMotif && <span className="text-danger">{errorMotif}</span>}
                    </div>
                    {!loading && <button className="btn btn-primary my-2" onClick={submitForm}>Enregistrer</button>}
                    {loading && <button className="btn btn-primary my-2" disabled>En cours...</button>}
                    
                </div>
                    </div>
                </div>
        </div>
    )
}

export default AbsencePage;