import {Routes as Switch, Route} from 'react-router-dom';
import PointagePage from './home/PointagePage';
import Dashboard from './home/Dashboard';
import Login from './auth/Login';
import AbsencePage from './home/AbsencePage';
import ListAbsencePage from './home/listAbsencePage';
import DetailAbsence from './home/DetailAbsence';
import StatistiquePage from './home/StatistiquePage';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import PDFGenerator from './home/Rapport';
import VisiteurPage from './home/sara/VisiteurPage';
import SuiviPage from './home/sara/SuiviPage';


const baseUrl = "http://pointageapp.akidompro.com/api";
//const baseUrl = "http://127.0.0.1:8000/api";
function Main(){
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const [dateDay,setDateDay] = useState(formattedDate);
    const [journee,setJournee] = useState([]);
    const [requestInterval, setRequestInterval] = useState(null);

    useEffect(()=>{
        //Requêtes programmée 
        const now = new Date();
        const targetTime = new Date(now);
        targetTime.setHours(23, 10, 0, 0);
        //const timeUntilTarget = 0 ;
        const timeUntilTarget = targetTime - now;
         
        if (timeUntilTarget < 0) {
            targetTime.setDate(targetTime.getDate() + 1);
            //timeUntilTarget = targetTime - now;
        }

        const initialTimer = setTimeout(() => {

            closeProgrammeDays();

            const interval = setInterval(() => {
                closeProgrammeDays();
            }, 24 * 60 * 60 * 1000);
            
            setRequestInterval(interval);
        }, timeUntilTarget);

        return () => {
            clearTimeout(initialTimer);
            clearInterval(requestInterval);
        };

    },[dateDay,journee])

    useEffect(()=>{
        axios.get(baseUrl+'/journee-ouverte-list/?active='+dateDay).then((resp)=>{
            setJournee(resp.data[0]);
        })
    },[]);

    const closeProgrammeDays=()=>{
        if (journee?.etat ){
            const _formData = new FormData();
            _formData.append('journee',journee.date);
            try {
                axios.post(baseUrl+'/cloture-day-automatics/',_formData).then((resp)=>{
                    if(resp.data.bool){
                        window.location.reload();
                    }else{
                        Swal.fire({
                            title: 'Ooops !',
                            text: resp.data.msg,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK'
                        });
                       
                    }
                });
            } catch (error) {
                console.log(error);
            }
        }
       
       }

    return (
        <div>

            <Switch>
                <Route path="/state/agro-map+2023+technologies-07-68-12-13-40/" element={<PointagePage />} />
                <Route path="/dashboard/" element={<Dashboard />} />

                <Route path="/auth/" element={<Login />} />
                <Route path="/add-absence/" element={<AbsencePage />} />
                <Route path="/list-absence/" element={<ListAbsencePage />} />
                <Route path="/statistique-generale/" element={<StatistiquePage />} />

                <Route path="/detail-absenceTo/:idEmpl/" element={<DetailAbsence />} />

                <Route path="/sara-visiteurs/" element={<VisiteurPage />} />
                <Route path="/sara-suivis-page/" element={<SuiviPage />} />
            </Switch>

        </div>
    )
}

export default Main;