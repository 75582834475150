import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

const baseUrl = "http://pointageapp.akidompro.com/api";
//const baseUrl = "http://127.0.0.1:8000/api";
function DetailAbsence(){
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const params = new URLSearchParams(window.location.search);
    const nomCompet = params.get('employe');
    const navigate = useNavigate();
    const {idEmpl} = useParams();
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_ucl'));
    const [client,setClients] = useState([]);
    const [listAbsences,setlistAbsences] = useState([]);

    useEffect(()=>{
        if(isAuthToken == null){
            localStorage.removeItem('_token_ucl');
            navigate('/auth/');
        }
        if(isAuthToken !== null){
            try {
                axios.get(baseUrl+'/users-clients/?token='+isAuthToken,{
                    headers:{
                        'Content-Type':'application/json'
                    },
                    credentials:'include'
                }).then((resp)=>{
                    if(resp.data.failed == true){
                        setIsAuthToken(null);
                        setClients(null);
                        localStorage.removeItem('_token_ucl');
                        navigate('/auth/');
                    }
                    setClients(resp.data[0]);
                })
            } catch (error) {
                console.log(error);
            }
        }

        // Requêtes sur la liste des absences par employés
        try {
            axios.get(baseUrl+'/employe-absence/?empl='+idEmpl).then((resp)=>{
                setlistAbsences(resp.data);
            })
        } catch (error) {
            console.log(error);
        } 
    },[nomCompet]);

    const logout = ()=>{
        setIsAuthToken(null);
        setClients(null);
        localStorage.removeItem('_token_ucl');
        navigate('/auth/');
    }

    const acheveFunction=(absence)=>{
        Swal.fire({
            title: 'ATTENTION !',
            text: 'Êtes-vous sûre de vouloir faire cette action.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText:"NON",
            confirmButtonText: 'OUI',
            cancelButtonColor:"red"
          }).then((result) => {
            if (result.isConfirmed) {
                try {
                    axios.get(baseUrl+'/employe-absence/?absence='+absence).then((resp)=>{
                        setlistAbsences(resp.data);
                    })
                } catch (error) {
                    console.log(error);
                }
            }
          }); 
        
    }

    return (
        <>
        <div className=" mt-4 mx-3">
       <div className="row">
     
           <aside className="col-md-2">
            <div className="card bg-warning">
               <div className="list-group list-group-flush ">
                    <Link className="list-group-item list-group-item-action text-primary" to={`/dashboard/`}>Accueil</Link> 
                    <Link className="list-group-item list-group-item-action text-primary" to={`/list-absence/`}>Gestion des Absences</Link> 
                    <Link className="list-group-item list-group-item-action text-primary" to={`/statistique-generale/`}>Statistique Générales</Link>
                    <button className="list-group-item list-group-item-action text-primary " onClick={()=>logout()}>Se Deconnecter</button> 
               </div>
           </div> 
           </aside>
       
           <section className="col-md-10">
               <div className="card">
                   <h5 className="card-header text-center">
                       <b className="mx-3">Page de detail absence</b>
                   </h5>
                <div className="card-body table-responsive-sm">
                        <div className="mb-3 row">
                           <div className="col-sm-12">
                            <h3>Liste des absences <b className="text-danger">{nomCompet}</b></h3>
                        </div>
                    </div>

                    <hr /> 
                {/*  {searchValue !=="" && <h5 className="text-dark">Recherche: "<span className="text-primary">{searchValue}</span>" <span className="float-end">Total : <b className="text-danger">{listemployePresents.length}</b></span></h5> } */}
                       <table class="table table-bordered  rounded p-2 border-transparent ">
                                   
                                   <thead className="bg-dark text-white">
                                       <tr>
                                           <th className="text-center">ID</th>
                                           <th className="text-center">Type d'absence</th>
                                           <th className="text-center">Date de depart</th>
                                           <th className="text-center">Durée d'absence</th>
                                           <th className="text-center">Date de retour</th>
                                           <th className="text-center" style={{"max-width":"50px"}}>Motif</th>
                                           <th className="text-center">Statut</th>
                                           <th className="text-center">Action</th> 
                                       </tr>
                                   </thead>
                           
                                    <tbody>
                                        {listAbsences.map((absence,index)=>
                                            <tr className={moment(formattedDate).format("Do MMMM YYYY") > moment(absence.dateRetoutProvisoire).format("Do MMMM YYYY") && absence.dateRetourConfirme==null && absence.etatAbsence ? "bg-info" : ""}>
                                                <td className="text-center">{index+1}</td>
                                                <td className="text-center">{absence.absence?.libelle}</td>
                                                <td className="text-center">{moment(absence.dateDepart).format("Do MMMM YYYY")}</td>
                                                <td className="text-center"><b>{absence.dureeAbsence} {absence.tempsAbsence}</b></td>
                                                <td className="text-center">{absence.dateRetourConfirme==null ? moment(absence.dateRetoutProvisoire).format("Do MMMM YYYY") : moment(absence.dateRetourConfirme).format("Do MMMM YYYY") }</td>
                                                <td className="text-center">{absence.motif}</td>
                                                <td className="text-center">{!absence.etatAbsence ? <span className="badge bg-danger">Terminer</span> : <span className="badge bg-success">Active</span>}</td>
                                                <td className="text-center">
                                                    <button className={absence.etatAbsence ? "btn btn-sm btn-warning" : "btn btn-sm btn-success disabled"} onClick={()=>acheveFunction(absence.id)}>Achever</button>
                                                </td>
                                            </tr>
                                        )}
                                        {listAbsences.length==0 && <span className="text-center">Tableau vide</span>}
                                    </tbody>
                                   </table>
                   </div>
               </div>
           </section>
       </div>
       </div>
  </>
    )
}

export default DetailAbsence;