import { Link, useNavigate } from "react-router-dom";
import Clock from "../Clock";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";


const baseUrl = "http://pointageapp.akidompro.com/api";
//const baseUrl = "http://127.0.0.1:8000/api";
function ListAbsencePage(){
    const navigate = useNavigate()

    const [listemployePresents,setEmployePresents] = useState([]);
    const [searchValue,setSearchValue] = useState('');
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_ucl'));
    const [client,setClients] = useState([]);

    useEffect(()=>{
        if(isAuthToken == null){
            localStorage.removeItem('_token_ucl');
            navigate('/auth/');
        }
        if(isAuthToken !== null){
            try {
                axios.get(baseUrl+'/users-clients/?token='+isAuthToken,{
                    headers:{
                        'Content-Type':'application/json'
                    },
                    credentials:'include'
                }).then((resp)=>{
                    if(resp.data.failed == true){
                        setIsAuthToken(null);
                        setClients(null);
                        localStorage.removeItem('_token_ucl');
                        navigate('/auth/');
                    }
                    setClients(resp.data[0]);
                })
            } catch (error) {
                console.log(error);
            }
        }
        try {
            axios.get(baseUrl+'/employes-list/?all').then((resp)=>{
                setEmployePresents(resp.data);
            })
        } catch (error) {
            console.log(error);
        }
    },[]);

    const searchName=(event)=>{
        setSearchValue(event.target.value);
      
            try {
                axios.get(baseUrl+'/employes-list/?values='+event.target.value).then((resp)=>{
                    setEmployePresents(resp.data);
                })
            } catch (error) {
                console.log(error);
            }
        
       
       }

    const DesactiveFunctionEmpl =(empl)=>{
        Swal.fire({
            title: 'ATTENTION !',
            text: 'Êtes-vous sûre de vouloir faire cette action.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText:"NON",
            confirmButtonText: 'OUI',
            cancelButtonColor:"red"
          }).then((result) => {
            if (result.isConfirmed) {
                try {
                    axios.get(baseUrl+'/employes-list/?empl='+empl).then((resp)=>{
                            setEmployePresents(resp.data);
                    })
                } catch (error) {
                    console.log(error);
                } 
            }
          }); 
        

    }

     const AddAbsenceTo=(nom,prenom,id)=>{
        //alert(index);
        window.$(`#openListPresent`).modal('hide');
        navigate(`/add-absence/?nom-complet=${nom} ${prenom}&empl=${id}`)
    
    } 

    const logout = ()=>{
        setIsAuthToken(null);
        setClients(null);
        localStorage.removeItem('_token_ucl');
        navigate('/auth/');
    }

    return (
       <>
             <div className=" mt-4 mx-3">
            <div className="row">
          
                <aside className="col-md-2">
                 <div className="card bg-warning">
                    <div className="list-group list-group-flush ">
                         <Link className="list-group-item list-group-item-action text-primary" to={`/dashboard/`}>Accueil</Link> 
                         <Link className="list-group-item list-group-item-action text-primary" to={`/list-absence/`}>Gestion des Absences</Link> 
                         <Link className="list-group-item list-group-item-action text-primary" to={`/statistique-generale/`}>Statistique Générales</Link>
                         <button className="list-group-item list-group-item-action text-primary " onClick={()=>logout()}>Se Deconnecter</button> 
                    </div>
                </div> 
                </aside>
            
                <section className="col-md-10">
                    <div className="card">
                        <h5 className="card-header text-center">
                            <b className="mx-3">Gestion des Absences</b>
                          
                        </h5>
                        <div className="card-body table-responsive-sm">
                            <div className="mb-3 row">
                                <div className="col-sm-6">
                                <input type="search" className="form-control border" name="password" placeholder="Saisir votre Nom.."  id="inputPassword" onChange={searchName} />
                                </div>
                            </div>

                            <hr />
                            {searchValue !=="" && <h5 className="text-dark">Recherche: "<span className="text-primary">{searchValue}</span>" <span className="float-end">Total : <b className="text-danger">{listemployePresents.length}</b></span></h5> }
                            <table class="table table-bordered  rounded p-2 border-transparent">
                                        
                                        <thead className="bg-dark text-white">
                                            <tr>
                                                <th className="text-center">ID</th>
                                                <th className="text-center">Nom</th>
                                                <th className="text-center">Prénoms</th>
                                                <th className="text-center">Poste</th>
                                                <th className="text-center">Etat</th>
                                                 <th className="text-center">Action</th> 
                                            </tr>
                                        </thead>
                                
                                            <tbody>
                                
                                                    {listemployePresents.map((employe,index)=>
                                                        <tr>
                                                        <td className="text-center">{index+1}</td>
                                                        <td className="text-center">{employe?.nom}</td>
                                                        <td className="text-center">{employe?.prenom}</td>
                                                        <td className="text-center">{employe?.profession}</td>
                                                        <td className="text-center">
                                                        {employe.isAbidjan  ?
                                                           <>
                                                               {employe.etatPresence && <b className="text-success">Présent</b>}
                                                               {!employe.etatPresence && <b className="text-danger">Absent</b>}
                                                           </>
                                                            
                                                            :
                                                            <b className="text-danger">Desactivé</b>
                                                        }
                                                        </td>
                                                         <td className="text-center">
                                                       
                           
                                                       {employe.isAbidjan ? 
                                                           <>
                                                               {employe.etatPresence && <button className="btn btn-sm btn-primary" title="Ajouter une absence"  onClick={()=>AddAbsenceTo(employe.nom,employe.prenom,employe.id)} ><i class="bi bi-card-checklist"></i></button>}
                                                             {/*   <div class="modal fade" id={`modalAddAbsence${index}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                   <div class="modal-dialog modal-dialog-centered">
                                                                       <div class="modal-content">
                                                                       
                                                                           <div class="modal-header">
                                                                               <h5 class="modal-title" id="modalLabelCreateAlbum">Signifier l'absence de {employe.nom} {employe.prenom}</h5>
                                                                               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                           </div>
                                                                       
                                                                           <div class="modal-body">
                                                                           
                                                                               <div class="row g-4">
                                                                                   
                                                                                   <div class="col-12">
                                                                                       <label class="form-label">Titre</label>
                                                                                       <input type="text" class="form-control" name="titre"  />
                                                                                   </div>
                                                                                   
                                                                                   <div class="col-12">
                                                                                       <label class="form-label">Motif</label>
                                                                                       <textarea class="form-control" rows="2" name="motif" ></textarea>
                                                                                   </div>
                                                                               </div>
                                                                           
                                                                           </div>
                                                                           <div class="modal-footer">
                                                                               <button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal"> Fermer</button>
                                                                               <button type="button" class="btn btn-success-soft" >Valider</button>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                               </div> */}
                                                               <Link className="btn btn-sm btn-success mx-1" title="Voir la liste des absences" to={`/detail-absenceTo/${employe.id}/?employe=${employe.nom} ${employe.prenom}`}><i class="bi bi-eye-fill"></i></Link>
                                                               <button className="btn btn-sm btn-danger mx-1" title="Désactiver un employé" onClick={()=>DesactiveFunctionEmpl(employe.id)}><i class="bi bi-play-circle"></i></button>
                                                           </>
                                                            :
                                                            ""
                                                       }
                                                       </td> 
                                                        
                                                    </tr>
                                                    )}
                                                
                                            </tbody>
                                        </table>
                        </div>
                    </div>
                </section>
            </div>
            </div>
       </>
    )
}

export default ListAbsencePage;